import Vue from 'vue'
import Vuex from 'vuex'
import request from "@/utils/request";
// 后台没有配置的路由。
import other from '../router/other'
import router from "@/router";

Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        tagsList: [],//历史菜单
        collapse: false,//是否收缩菜单
        menuList: [],//菜单
    },
    mutations: {
        delTagsItem(state, data) {
            state.tagsList.splice(data.index, 1);
        },
        setTagsItem(state, data) {
            state.tagsList.push(data)
        },
        clearTags(state) {
            state.tagsList = []
        },
        closeTagsOther(state, data) {
            state.tagsList = data;
        },
        closeCurrentTag(state, data) {
            for (let i = 0, len = state.tagsList.length; i < len; i++) {
                const item = state.tagsList[i];
                if (item.path === data.$route.fullPath) {
                    if (i < len - 1) {
                        data.$router.push(state.tagsList[i + 1].path);
                    } else if (i > 0) {
                        data.$router.push(state.tagsList[i - 1].path);
                    } else {
                        // data.$router.push("/");
                        var menuList= state.menuList
                        if(menuList[0].sub&&menuList[0].sub.length){
                            data.$router.push(menuList[0].sub[0].front_menu);
                        }else{
                            data.$router.push(menuList[0].front_menu);
                        }
                    }
                    state.tagsList.splice(i, 1);
                    break;
                }
            }
        },
        // 侧边栏折叠
        handleCollapse(state, data) {
            state.collapse = data;
        },

        MENULIST(state, data) {
            state.menuList = data
        }
    },
    actions: {

        async fetchMenuList({dispatch, commit}) {
            await request({
                url: '/admin/sys_rule/menuLeft',
                method: "get"
            }).then(res => {
                if (res.code == 200) {
                    commit('MENULIST', res.data)
                    // dispatch('fetchMenuList')

                    // 去初始化路由
                    let initRoters = []
                    // 主页，后台返回的页面，都放在主页下。
                    let homePage = {
                        path: '/',
                        name: 'home',
                        component: resolve => require(['@/views/HomeView'], resolve),
                        meta: {},
                        children: [
                            ...other
                        ],
                        beforeRouteLeave(to, form, next) {
                            next()
                        }
                    }

                    let buildMenuItem = (menus, p_menu) => {
                        menus.forEach(m => {
                            if (m.component && m.type == 2) {
                                let obj = {
                                    path: m.front_menu,
                                    name: m.front_menu,
                                    meta: {
                                        title: m.title,
                                        parentId: m.pid,
                                        parentMenu: p_menu ? p_menu.title + ' > ' + m.title : ''
                                    },
                                    component: () => import(`@/views${m.component}`)
                                }
                                // 配置重定向页面
                                if (m.redirect) {
                                    obj.redirect = m.redirect
                                }
                                homePage.children.push(obj)
                            }
                            if (m.sub && m.sub.length > 0) {
                                buildMenuItem(m.sub, m);
                            }
                        })
                        if (menus.sub && menus.sub.length) {
                            buildMenuItem(menus.sub, menus)
                        }
                    }
                    buildMenuItem(res.data)

                    initRoters.push({
                        path: '*',
                        name: 'notFound',
                        meta: {},
                        component: resolve => require(['@/views/NotFound'], resolve)
                    })
                    // home 页面
                    initRoters.push(homePage);

                    // 新建一个全新的 Router，然后将新的 Router.matcher 赋给当前页面的管理 Router，以达到更新路由配置的目的。
                    // router.matcher = new Router({mode: 'history'}).matcher
                    // 新加获取到的路由
                    router.addRoutes(initRoters)

                }
            })
        }

    },
    modules: {}
})


