<template>
  <div class="about">
    <layout-header/>
    <layout-sidebar :collapse="collapse" :is-active-menu="activeMenu"/>
    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <layout-tags :is-active="isActiveMenu"></layout-tags>
      <div class="content">
        <router-view v-slot="{ Component }">
          <transition name="move" mode="out-in">
            <keep-alive :include="tagsList">
              <component :is="Component"/>
            </keep-alive>
          </transition>
        </router-view>
<!--        <router-view ></router-view>-->
        <!-- <el-backtop target=".content"></el-backtop> -->
      </div>
    </div>
  </div>
</template>
<script>


import LayoutHeader from "../components/LayoutHeader.vue";
import LayoutSidebar from "../components/LayoutSidebar.vue";
import LayoutTags from "../components/LayoutTags.vue";

export default {
  components: {LayoutHeader, LayoutSidebar, LayoutTags,},
  data() {
    return {
      activeMenu:"",//选中的左侧菜单
      isActiveMenu:""
    }
  },
  computed: {
    tagsList() {
      return this.$store.state.tagsList
    },
    collapse() {
      return this.$store.state.collapse
    }
  },
  watch: {
    $route(to ) {
      this.activeMenu = this.$route.meta.activePath ? this.$route.meta.activePath : this.$route.fullPath
      this.isActiveMenu=this.$route.fullPath
      const isExist = this.tagsList.some((item) => {
        return item.path === to.fullPath;
      });
      if (!isExist) {
        if (this.tagsList.length >= 8) {
          this.$store.commit("delTagsItem", { index: 0 });
        }
        this.$store.commit("setTagsItem", {
          name: to.name,
          title: to.meta.title,
          path: to.fullPath,
        });
      }

    },

  },
  methods: {},
  mounted() {
    this.activeMenu = this.$route.meta.activePath ? this.$route.meta.activePath : this.$route.fullPath
    this.isActiveMenu=this.$route.fullPath
    // this.$store.dispatch('fetchMenuList')
  }

};
</script>
<style>


.content-box {
  position: absolute;
  left: 200px;
  right: 0;
  top: 70px;
  bottom: 0;
  padding-bottom: 30px;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out;
  background: #f0f0f0;
}

.content {
  width: auto;
  height: 100%;
  padding: 15px;
  overflow-y: scroll;
  box-sizing: border-box;
}

.content-collapse {
  left: 65px;
}

.container {
  padding: 30px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.crumbs {
  margin: 10px 0;
}

</style>
