<template>
  <div class="sidebar">
<!--    background-color="#324157" text-color="#bfcbd9" active-text-color="#20a0ff"-->
    <el-menu class="sidebar-el-menu" :default-active="isActiveMenu" :collapse="collapse" unique-opened router>
      <template v-for="item in menuList">
        <template v-if="item.sub">
          <el-submenu :index="item.id+''" :key="item.id">
            <template #title>
<!--              <i :class="item.icon"></i>-->
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.sub">
              <el-submenu v-if="subItem.sub" :index="subItem.id+''" :key="subItem.id">
                <template #title>{{ subItem.title }}</template>
                <el-menu-item v-for="(threeItem, i) in subItem.sub" :key="i" :index="threeItem.id">
                  {{ threeItem.title }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="subItem.front_menu" :key="subItem.id">{{ subItem.title }}
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.front_menu" :key="item.id">
<!--            <i :class="item.icon"></i>-->
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>

export default {
  props:['collapse','isActiveMenu'],
  computed:{
    menuList(){
      return this.$store.state.menuList
    }
  },
  data(){
    return{

      onRoutes:""
    }
  },

  methods:{

  },
  mounted() {
    this.onRoutes = this.$route.path
  }

};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.sidebar > ul {
  height: 100%;
}
</style>
