<template>
  <div class="layout_header">
    <!-- 折叠按钮 -->
    <!--    <div class="collapse-btn" @click="collapseChage">
          <i v-if="!collapse" class="el-icon-s-fold"></i>
          <i v-else class="el-icon-s-unfold"></i>
        </div>-->
    <div class="logo" style="margin-left: 20px">后台管理系统</div>
    <div class="layout_header-right">
      <div class="layout_header-user-con">
        <!-- 消息中心 -->
<!--        <div class="btn-bell">-->
<!--          <el-tooltip effect="dark" :content="message?`有${message}条未读消息`:`消息中心`" placement="bottom">-->
<!--            <router-link to="/tabs">-->
<!--              <i class="el-icon-bell"></i>-->
<!--            </router-link>-->
<!--          </el-tooltip>-->
<!--          <span class="btn-bell-badge" v-if="message"></span>-->
<!--        </div>-->
        <!-- 用户头像 -->
        <!--        <div class="user-avator">
                  <img src=""/>
                </div>-->
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{ userInfo.account }}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="resetPaw">修改密码</el-dropdown-item>
              <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <el-dialog title="修改密码" width="500px" :visible.sync="pwd_show" :before-close="closePwdShow">
      <el-form :model="pwdInfo" :rules="rules" label-width="100px" ref="pwdForm" style="width: 330px;margin:0 auto">
        <el-form-item label="原密码：" prop="oldPass" size="small">
          <el-input type="password" class="w_px_220" placeholder="请输入原密码" v-model="pwdInfo.oldPass"></el-input>
        </el-form-item>

        <el-form-item label="新密码：" prop="newPassOne">
          <el-input type="password" class="w_px_220" placeholder="请输入新密码" v-model="pwdInfo.newPassOne"></el-input>
        </el-form-item>

        <el-form-item label="确认密码：" prop="newPassTwo">
          <el-input type="password" class="w_px_220" placeholder="请输入确认密码" v-model="pwdInfo.newPassTwo"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="small" @click="closePwdShow">取消</el-button>
          <el-button :loading="pwdLoading" type="primary" size="small" @click="submitPwd">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.pwdInfo.newPassOne) {
        callback(new Error("新密码和确认密码不一致!请重新输入"));
      } else {
        callback();
      }
    };
    return {
      userInfo: {},
      message: 2,
      pwdInfo: {},
      pwd_show: false,
      rules: {
        oldPass: [{required: true, message: "请输入原密码", trigger: "blur"}],
        newPassOne: [{required: true, message: "请输入新密码", trigger: "blur"}],
        newPassTwo: [{required: true, validator: validatePass, trigger: "blur"}]
      },
      pwdLoading: false
    }
  },
  computed: {
    collapse() {
      return this.$store.state.collapse
    },

  },
  methods: {
    // 侧边栏折叠
    collapseChage() {
      this.$store.commit("handleCollapse", !this.collapse);
    },
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        this.handleLoginOut()
      } else if (command == "resetPaw") {
        this.pwd_show = true
      }
    },
    closePwdShow(){
      this.$refs.pwdForm.clearValidate()
      this.pwd_show = false
    },
    submitPwd() {
      this.$refs.pwdForm.validate((vaild) => {
        if (vaild) {
          this.pwdLoading = true
          this.$http.post('admin/sysUser/resetPass', this.pwdInfo).then(res => {
            this.pwdLoading = false
            if (res.code == 200) {
              this.$message.success('修改成功，请重新登录')
              this.handleLoginOut()
            }
          })
        }
      })
    },
    handleLoginOut() {
      this.$store.commit("clearTags");
      Cookies.remove('ydd_TOKEN')
      if(!this.userInfo.isRemember){
        sessionStorage.clear()
      }
      this.$router.push("/login");
    },
  },
  mounted() {
    this.userInfo = sessionStorage.getItem('ad_userInfo') ? JSON.parse(sessionStorage.getItem('ad_userInfo')) : {}
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  }
};
</script>
<style scoped>

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}

.btn-bell .el-icon-bell {
  color: #fff;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 20px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.el-dropdown-link {
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.el-dropdown-menu__item {
  text-align: center;
}
</style>
